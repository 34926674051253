import React from "react"
import Navbar from "../../components/navbar"
import BackgroundShape from "../../components/backgroundShape"
import Container from "../../components/container"
import Header from "../../components/header"
import Head from "../../components/head"
import Layout from "../../components/layout"
import OnboardingCardSm from "../../components/onboardingCardSm"
import { Link } from 'gatsby'

const Shipping = () =>  {
    return (
        <Layout navbar="darkblue">
        <Head title="Changelog" description="Learn how to connect with multiple vendors, manage your inventory, sales, customers and more with our step-by-step tutorials"/>
            <BackgroundShape style={{shape: "clip-fixed-small", color: "darkblue", particles: true}}/>
                <Container style={{classes: "container" }}>
                <div className="position-relative">
                    <Header content={{title: "Shipping strategies", text: "Will you charge your customers at checkout?Your shipping strategy is key to the growth and success of your business. An adequate strategy may be the difference between profitability or loss.s"}} style={{color: "text-center"}}/>
                    <Link to='/onboarding' className='btnOn dark-bg' style={{position:'absolute', top: '246px', left:'-14px' }}>← Back</Link>
                </div>
                </Container>
                <Container style={{classes: "container mb-5" }}>
                <div style={{background: '#F4F6F8', padding:'40px 0', margin:'2rem -15px 0 -15px'}} data-sal="slide-up" data-sal-delay='200' data-sal-easing="ease" data-sal-duration="300">
                        <p style={{fontWeight:'700', fontSize: "1rem", marginTop: '8px', textAlign:'center'}}>Will you <span style={{color:'#3b77ce'}}>charge</span> your customers at checkout?</p>
                        {/* <p style={{fontWeight:'300', marginTop: '8px', textAlign:'center'}}>Lorem ipsum dolor sit amet</p> */}
                        <div className="row align-items-start justify-content-around my-5">
                            <div className="text-center col-12 col-md-4">
                                <img src='https://taact-brand-assets.netlify.app/assets/icons/onport/hide-alt.svg' style={{height:'48px', marginBottom: '16px'}} data-sal="slide-up" data-sal-delay='400' data-sal-easing="ease" data-sal-duration="300"/>
                                <a className="btn-onboarding" target="_blank" href="https://help.jetti.io/hc/en-us/articles/7109118452636-Apply-Free-Shipping-at-Customer-Checkout">
                                    <p style={{background:'#CEE0FB', textTransform:'uppercase', color:'#3b5170', margin: '0 auto', padding:'8px 16px', width:'140px', fontSize:'.6rem', fontWeight:'bolder', borderRadius:'8px'}}>No</p>
                                </a>
                                <p style={{fontWeight:'300', marginTop: '8px', padding:'0 16px'}}>Do not charge customers for shipping at checkout.</p>
                            </div>
                            <div className="text-center col-12 col-md-4">
                                <img src='https://taact-brand-assets.netlify.app/assets/icons/onport/vendor.svg' style={{height:'48px', marginBottom: '16px'}} data-sal="slide-up" data-sal-delay='600' data-sal-easing="ease" data-sal-duration="300"/>
                                    <p style={{background:'#CEE0FB', textTransform:'uppercase', color:'#3b5170', margin: '0 auto', padding:'8px 16px', width:'140px', fontSize:'.6rem', fontWeight:'bolder', borderRadius:'8px'}}>Yes, cart-level shipping</p>
                                <p style={{fontWeight:'300', marginTop: '8px', padding:'0 16px'}}>Your e-commerce store will be the rate master. This rate will apply at checkout and it will be fixed even for multi-vendor orders.</p>
                            </div>
                            <div className="text-center col-12 col-md-4">
                                <img src='https://taact-brand-assets.netlify.app/assets/icons/onport/reseller.svg' style={{height:'48px', marginBottom: '16px'}} data-sal="slide-up" data-sal-delay='800' data-sal-easing="ease" data-sal-duration="300"/>
                                <a className="btn-onboarding" target="_blank" href="https://jettisupport.zendesk.com/hc/en-us/sections/5724361944476-Vendor-Shipping-Rates-Setup">
                                    <p style={{background:'#CEE0FB', textTransform:'uppercase', color:'#3b5170', margin: '0 auto', padding:'8px 16px', width:'140px', fontSize:'.6rem', fontWeight:'bolder', borderRadius:'8px'}}>Yes, vendor level shipping</p>
                                </a>
                                <p style={{fontWeight:'300', marginTop: '8px', padding:'0 16px'}}>Onport will be the rate master. These rates are flexible and can be applied at a vendor level.(Shopify only) </p>
                            </div>
                        </div>
                        </div>
                        <p className='mt-5' style={{fontWeight:'700', fontSize: "1rem", textAlign:'center'}}>Who will generate the <span style={{color:'#3b77ce'}}>rates and labels</span>?</p>
                        <div className="row">
                        <div className="mt-5 col-12 col-md-6" style={{paddingRight:'40px'}}>
                            <div style={{background: '#F4F6F8', height: '500px',padding:'24px 0 42px 0'}}>
                                <p style={{textAlign:'center', marginBottom: '24px', fontWeight:'700', marginTop: '8px', textTransform:'uppercase', color:'#3b77ce'}}>Provide Shipping labels</p>
                                <div className="text-center px-5">
                                    <a className="btn-onboarding" target="_blank" href="https://jettisupport.zendesk.com/hc/en-us/articles/7281463352476-Provide-Shipping-Labels-to-your-Vendors ">
                                        <p style={{background:'#CEE0FB', textTransform:'uppercase', color:'#3b5170', margin: '0 auto', padding:'8px 16px', width:'140px', fontSize:'.6rem', fontWeight:'bolder', borderRadius:'8px'}}>Use labels generated in Onport</p>
                                    </a>
                                        <p style={{fontWeight:'700', marginTop: '8px', textAlign:'center'}}>Marketplace provides both</p>
                                    <p style={{fontWeight:'300', marginTop: '8px', padding:'0 16px'}}>The <b>vendors</b> can print domestic and international labels in the vendor portal or via e-commerce integration. You can either use <b>Onport Logistics</b>, our shipping aggregator integrations or develop your own custom carrier integrations.</p>
                                </div>
                                <div className="text-center px-5 mt-4">
                                    <a className="btn-onboarding" target="_blank" href="https://help.jetti.io/hc/en-us/articles/7281463352476-Provide-Shipping-Labels-to-your-Vendors#:~:text=Generated%20in%20Onport-,Labels%20Generated%20outside%20of%20Onport,-Powered%20by%20Zendesk">
                                        <p style={{background:'#CEE0FB', textTransform:'uppercase', color:'#3b5170', margin: '0 auto', padding:'8px 16px', width:'140px', fontSize:'.6rem', fontWeight:'bolder', borderRadius:'8px'}}>Use labels generated outside of Onport</p>
                                    </a>
                                    <p style={{fontWeight:'700', marginTop: '8px', textAlign:'center'}}>Marketplace provides both</p>
                                    <p style={{fontWeight:'300', marginTop: '8px', padding:'0 16px'}}>If you have your own agreement with a logistics provider, you can generate the labels outside of Onport and upload them manually into the vendor portal or use any other communication means.</p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 col-12 col-md-6" style={{paddingLeft:'40px'}}>
                            <div style={{background: '#F4F6F8', height: '500px',padding:'24px 0 42px 0'}}>
                            <p style={{textAlign:'center', marginBottom: '24px', fontWeight:'700', marginTop: '8px', textTransform:'uppercase', color:'#3b77ce'}}>No labels are provided</p>
                                <div className="text-center px-5">
                                    <a className="btn-onboarding" target="_blank" href="https://help.jetti.io/hc/en-us/articles/7155120317596">
                                        <p style={{background:'#CEE0FB', textTransform:'uppercase', color:'#3b5170', margin: '0 auto', padding:'8px 16px', width:'140px', fontSize:'.6rem', fontWeight:'bolder', borderRadius:'8px'}}>Use the same shipping rate paid by the customer</p>
                                    </a>
                                    <p style={{fontWeight:'700', marginTop: '8px', textAlign:'center'}}>Marketplace generates rates | Vendor generates labels</p>
                                    <p style={{fontWeight:'300', marginTop: '8px', padding:'0 16px'}}>Pass along the shipping rates charged to the customer at checkout.</p>
                                </div>
                                <div className="text-center px-5 mt-5">
                                    <a className="btn-onboarding" target="_blank" href="https://help.jetti.io/hc/en-us/sections/5724361944476-Vendor-Shipping-Rates-Setup">
                                        <p style={{background:'#CEE0FB', textTransform:'uppercase', color:'#3b5170', margin: '0 auto', padding:'8px 16px', width:'140px', fontSize:'.6rem', fontWeight:'bolder', borderRadius:'8px'}}>Use vendor shipping rates</p>
                                    </a>
                                    <p style={{fontWeight:'700', marginTop: '8px', textAlign:'center'}}>Vendor generates both</p>
                                    <p style={{fontWeight:'300', marginTop: '8px', padding:'0 16px'}}>The vendors will generate their own labels and ship on rates agreed with the marketplace.</p>
                                </div>
                            </div>
                        </div>
                        </div>

                        <div className="row my-5 justify-content-around" style={{background: '#F4F6F8', padding: '24px 0'}}>
                            <div className="text-center col-12 col-md-4">
                                <img src='https://taact-brand-assets.netlify.app/assets/icons/onport/ticket.svg' style={{height:'48px', marginBottom: '16px'}}data-sal= "slide-up" data-sal-delay='600' data-sal-easing="ease" data-sal-duration="300"/>
                                <a className="btn-onboarding" target="_blank" href="https://jettisupport.zendesk.com/hc/en-us/articles/7109104066332-Shipping-Edge-Case-Scenarios">
                                    <p style={{background:'#CEE0FB', textTransform:'uppercase', color:'#3b5170', margin: '0 auto', padding:'8px 16px', width:'140px', fontSize:'.6rem', fontWeight:'bolder', borderRadius:'8px'}}>Other</p>
                                </a>
                                <p style={{fontWeight:'700', marginTop: '8px', textAlign:'center'}}>Edge-case scenarios</p>
                                <p style={{fontWeight:'300', marginTop: '8px', padding:'0 16px'}}>There are other scenarios that could be more suitable. Please check the article below where we describe all the remaining options available.</p>
                            </div>
                        </div>
                        <div style={{background: '#F4F6F8', padding:'40px 0', margin:'2rem -15px 0 -15px'}} data-sal="slide-up" data-sal-delay='200' data-sal-easing="ease" data-sal-duration="300">
                        <p style={{fontWeight:'700', fontSize: "1rem", marginTop: '8px', textAlign:'center'}}>How can you create <span style={{color:'#3b77ce'}}>vendor shipping rates</span> in Onport?</p>
                        {/* <p style={{fontWeight:'300', marginTop: '8px', textAlign:'center'}}>Lorem ipsum dolor sit amet</p> */}
                        <div className="row align-items-start justify-content-around mt-5">
                            <div className="text-center col-12 col-md-4">
                                <img src='https://taact-brand-assets.netlify.app/assets/icons/onport/shipping.svg' style={{height:'48px', marginBottom: '16px'}} data-sal="slide-up" data-sal-delay='400' data-sal-easing="ease" data-sal-duration="300"/>
                                <a className="btn-onboarding" target="_blank" href="https://jettisupport.zendesk.com/hc/en-us/articles/6590697999132-Shipping-Rates-How-to-Create-Live-Carrier-Rates">
                                    <p style={{background:'#CEE0FB', textTransform:'uppercase', color:'#3b5170', margin: '0 auto', padding:'8px 16px', width:'140px', fontSize:'.6rem', fontWeight:'bolder', borderRadius:'8px'}}>Live carrier rates</p>
                                </a>
                                <p style={{fontWeight:'300', marginTop: '8px', padding:'0 16px'}}>Use Onport to generate live carrier rates to guarantee the best price for label generation. You can either use our discounted USPS rates or connect your own logistics provider account to access all of your own carriers.</p>
                            </div>
                            <div className="text-center col-12 col-md-4">
                                <img src='https://taact-brand-assets.netlify.app/assets/icons/onport/chart.svg' style={{height:'48px', marginBottom: '16px'}}data-sal= "slide-up" data-sal-delay='600' data-sal-easing="ease" data-sal-duration="300"/>
                                <a className="btn-onboarding" target="_blank" href="https://jettisupport.zendesk.com/hc/en-us/articles/5521001108764-Shipping-Rates-How-to-Create-Fixed-Price-per-Shipment-Rates">
                                    <p style={{background:'#CEE0FB', textTransform:'uppercase', color:'#3b5170', margin: '0 auto', padding:'8px 16px', width:'140px', fontSize:'.6rem', fontWeight:'bolder', borderRadius:'8px'}}>Fixed price per shipment</p>
                                </a>
                                <p style={{fontWeight:'300', marginTop: '8px', padding:'0 16px'}}>Enter the fixed price per shipment charged to the customer. This same price will be applied to domestic and international shipments.</p>
                            </div>
                        </div>
                        <div className="row align-items-start justify-content-around mt-5">
                            <div className="text-center col-12 col-md-4">
                                <img src='https://taact-brand-assets.netlify.app/assets/icons/onport/order.svg' style={{height:'48px', marginBottom: '16px'}} data-sal="slide-up" data-sal-delay='400' data-sal-easing="ease" data-sal-duration="300"/>
                                <a className="btn-onboarding" target="_blank" href="https://jettisupport.zendesk.com/hc/en-us/articles/5521008472604-Shipping-Rates-How-to-Create-Fixed-Price-per-Item-Rates ">
                                    <p style={{background:'#CEE0FB', textTransform:'uppercase', color:'#3b5170', margin: '0 auto', padding:'8px 16px', width:'140px', fontSize:'.6rem', fontWeight:'bolder', borderRadius:'8px'}}>Fixed price per item</p>
                                </a>
                                <p style={{fontWeight:'300', marginTop: '8px', padding:'0 16px'}}>Enter the fixed price per shipment charged to the customer, plus an option fee per item. This same price will be applied to domestic and international shipments.</p>
                            </div>
                            <div className="text-center col-12 col-md-4">
                                <img src='https://taact-brand-assets.netlify.app/assets/icons/onport/commission-rates.svg' style={{height:'48px', marginBottom: '16px'}}data-sal= "slide-up" data-sal-delay='600' data-sal-easing="ease" data-sal-duration="300"/>
                                <a className="btn-onboarding" target="_blank" href="https://jettisupport.zendesk.com/hc/en-us/articles/5521042064028-Shipping-Rates-How-to-Create-Weight-or-Price-Based-Rates ">
                                    <p style={{background:'#CEE0FB', textTransform:'uppercase', color:'#3b5170', margin: '0 auto', padding:'8px 16px', width:'140px', fontSize:'.6rem', fontWeight:'bolder', borderRadius:'8px'}}>Weight or price based shipping</p>
                                </a>
                                <p style={{fontWeight:'300', marginTop: '8px', padding:'0 16px'}}>Charge different rates depending on the order weight or value. Different rates can be charged for domestic and international shipments if needed.</p>
                            </div>
                        </div>
                        <div className="row align-items-start my-5 justify-content-around">
                            <div className="text-center col-12 col-md-4">
                                <img src='https://taact-brand-assets.netlify.app/assets/icons/onport/location.svg' style={{height:'48px', marginBottom: '16px'}}data-sal= "slide-up" data-sal-delay='600' data-sal-easing="ease" data-sal-duration="300"/>
                                <a className="btn-onboarding" target="_blank" href="https://jettisupport.zendesk.com/hc/en-us/articles/360021108919-Shipping-Rates-How-to-Set-up-Shipping-Zones ">
                                    <p style={{background:'#CEE0FB', textTransform:'uppercase', color:'#3b5170', margin: '0 auto', padding:'8px 16px', width:'140px', fontSize:'.6rem', fontWeight:'bolder', borderRadius:'8px'}}>Advanced shipping rules (zones)</p>
                                </a>
                                <p style={{fontWeight:'300', marginTop: '8px', padding:'0 16px'}}>Charge rates depending on the order weight or value. Create shipping zones to charge different prices across regions or countries.</p>
                            </div>
                        </div>
                        </div>
                        <div style={{background: '#F4F6F8', padding:'40px 0', margin:'2rem -15px 0 -15px'}} data-sal="slide-up" data-sal-delay='200' data-sal-easing="ease" data-sal-duration="300">
                        <p style={{fontWeight:'700', fontSize: "1rem", marginTop: '8px', textAlign:'center'}}>Will you invoice your <span style={{color:'#3b77ce'}}>vendors</span> for shipping?</p>
                        {/* <p style={{fontWeight:'300', marginTop: '8px', textAlign:'center'}}>Lorem ipsum dolor sit amet</p> */}
                        <div className="row align-items-start justify-content-around mt-5">
                            <div className="text-center col-12 col-md-4">
                                <img src='https://taact-brand-assets.netlify.app/assets/icons/onport/rules.svg' style={{height:'48px', marginBottom: '16px'}} data-sal="slide-up" data-sal-delay='400' data-sal-easing="ease" data-sal-duration="300"/>
                                <a className="btn-onboarding" target="_blank" href="https://jettisupport.zendesk.com/hc/en-us/articles/5749643126300-Marketplace-Invoices-Vendor-for-Shipping-Costs ">
                                    <p style={{background:'#CEE0FB', textTransform:'uppercase', color:'#3b5170', margin: '0 auto', padding:'8px 16px', width:'140px', fontSize:'.6rem', fontWeight:'bolder', borderRadius:'8px'}}>Yes</p>
                                </a>
                                <p style={{fontWeight:'300', marginTop: '8px', padding:'0 16px'}}>Marketplace charges the vendor for shipping.</p>
                            </div>
                            <div className="text-center col-12 col-md-4">
                                <img src='https://taact-brand-assets.netlify.app/assets/icons/onport/payments.svg' style={{height:'48px', marginBottom: '16px'}}data-sal= "slide-up" data-sal-delay='600' data-sal-easing="ease" data-sal-duration="300"/>
                                <a className="btn-onboarding" target="_blank" href="https://jettisupport.zendesk.com/hc/en-us/articles/360021109539-Product-Publishing#connecting-products-to-jetti-that-already-exist-in-your-store">
                                    <p style={{background:'#CEE0FB', textTransform:'uppercase', color:'#3b5170', margin: '0 auto', padding:'8px 16px', width:'140px', fontSize:'.6rem', fontWeight:'bolder', borderRadius:'8px'}}>Yes</p>
                                </a>
                                <p style={{fontWeight:'300', marginTop: '8px', padding:'0 16px'}}>Marketplace pays the vendor for shipping.</p>
                            </div>
                            <div className="text-center col-12 col-md-4">
                                <img src='https://taact-brand-assets.netlify.app/assets/icons/onport/hide-alt.svg' style={{height:'48px', marginBottom: '16px'}}data-sal= "slide-up" data-sal-delay='600' data-sal-easing="ease" data-sal-duration="300"/>
                                <a className="btn-onboarding" target="_blank" href="https://jettisupport.zendesk.com/hc/en-us/articles/360021109539-Product-Publishing#connecting-products-to-jetti-that-already-exist-in-your-store">
                                    <p style={{background:'#CEE0FB', textTransform:'uppercase', color:'#3b5170', margin: '0 auto', padding:'8px 16px', width:'140px', fontSize:'.6rem', fontWeight:'bolder', borderRadius:'8px'}}>No</p>
                                </a>
                                <p style={{fontWeight:'300', marginTop: '8px', padding:'0 16px'}}>No transactions occur between marketplace and vendor regarding shipping.</p>
                            </div>
                        </div>
                        </div>
                <div className="row">
                <OnboardingCardSm content={{
                    slug: 'create-product', 
                    category: 'INVENTORY', 
                    step:'← Previous',
                    src: "https://taact-brand-assets.netlify.app/assets/illustrations/inventory.svg", 
                    title: "Product catalog", 
                    }}/>
                    <OnboardingCardSm content={{
                    slug: 'order', 
                    category: 'ORDERS',
                    step:'Next →', 
                    src: "https://taact-brand-assets.netlify.app/assets/illustrations/send-order.svg", 
                    title: "Order management", 
                    }}/>
                </div>
                </Container>
        </Layout>
    )
}

export default Shipping