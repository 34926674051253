import React from "react"
import { Link } from "gatsby"
import TitleCategory from "./new/titleCategory/titleCategory"
import Block from "./new/block/block"
import Paragraph from "./new/paragraph/paragraph"
import SubHeader from "./new/subHeader/subHeader"
import Grid from "./new/grid/grid"

const OnboardingCardSm = (props) => {
   return (
     <div className="col-12 col-md-6 text-center">
        <p style={{marginTop:'3rem', fontSize:'1.2rem'}}>{props.content.step}</p>
        <div className="onboarding-card-sm">
        <Link to={`/onboarding/${ props.content.slug }`}>
          {/* <img src={props.content.src}  alt={props.content.title}/> */}
            <h3>{props.content.title}</h3>
        </Link>
      </div>
      </div>
    )
}

export default OnboardingCardSm
