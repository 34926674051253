import React from "react"
import ReactHtmlParser from 'react-html-parser';
import "./subHeader.scss"

const SubHeader = ({title, style, anim, delay, duration}) => {
  return (
        <h2 className={`sub-headerOn ${style || ''}`} data-sal={anim} data-sal-delay={delay || '300'} data-sal-easing="ease" data-sal-duration={duration || "600"}>{ReactHtmlParser(title)}</h2>
    )
}

export default SubHeader
